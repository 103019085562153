import React from 'react';
import MarketingPage from '../../components/layout/MarketingPage';
import HomeContent from './HomeContent';

const HomePage = () => {
  return (
    <MarketingPage>
      <HomeContent />
    </MarketingPage>
  );
};

export default HomePage;
