import React, { useState, useEffect } from 'react';
import { CNavItem, CNavbar, CContainer, CNavbarBrand, CNavbarToggler, CCollapse, CNavbarNav, CNavLink, CAlert, CAlertHeading } from '@coreui/react';
import './newLaunchHeader.scss';

const NewLaunchHeader = () => {
  const [visible, setVisible] = useState(false);
  const [pathName, setPathName] = useState("");

  useEffect(() => {
    if(window) {
      setPathName(window.location.pathname)  
    }
  }, []);

  return (
    <>
      <CNavbar expand="lg" colorScheme="light" color="string">
        <CContainer fluid>
          <CNavbarBrand href="/">talkdoc</CNavbarBrand>
          <CNavbarToggler onClick={() => setVisible(!visible)} />
          <CCollapse className="navbar-collapse" visible={visible}>
            <CNavbarNav>
              <CNavItem>
                <CNavLink href="/clinicians">For Clinicians</CNavLink>
              </CNavItem>
            </CNavbarNav>
          </CCollapse>
        </CContainer>
      </CNavbar>
    </>
  );
};

export default NewLaunchHeader;
