require("dotenv").config();
import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { BsCheckLg } from "react-icons/bs";
import classNames from "classnames";
import clinician1 from "assets/img/clinician1.png";
import clinician2 from "assets/img/clinician2.jpg";
import clinician3 from "assets/img/clinician3.png";
import clinician4 from "assets/img/clinician4.png";
import "./cliniciansSection.scss";

// const CLIENT_HOST = 'https://dxql6l7391yjm.cloudfront.net/public/img'
let CLIENT_HOST = `${process.env.CLIENT_HOST}/public/img`;

if (process.env.NODE_ENV === "local") CLIENT_HOST = `assets/img`;

const CLINICIANS = [
  // {
  //   photo: clinician1,
  //   name: "Deborah Sterling, LCSW",
  //   specialty: "ADHD, Depression & Anxiety, Communication Skills"
  // },
  // {
  //   photo: clinician2,
  //   name: "Dr. Alex Chen, M.D.",
  //   specialty: "Depression & Anxiety, Bipolar Disorder, Schizophrenia"
  // },
  {
    photo: `${CLIENT_HOST}/clinician3.png`,
    name: "Dr. Neelam Sachdev, M.D.",
    specialty: "Child & Adolescent Psychiatry, Insomnia, OCD",
  },
  // {
  //   photo: clinician4,
  //   name: "Dr. Marcella Wilson, M.D.",
  //   specialty: "Depression & Anxiety, Bipolar Disorder, Women's Issues"
  // }
];
const CAROUSEL_TIMER = 5000;

const getClinicianClassName = ({ currentImgID, imgID }) => {
  return classNames("clinician-animation", {
    "clinician-animation--visible": currentImgID === imgID,
  });
};

const CliniciansSection = ({ clinicians = CLINICIANS }) => {
  const [imgIndex, setImgIndex] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setImgIndex((imgIndex) => (imgIndex + 1) % clinicians.length);
    }, CAROUSEL_TIMER);
    return () => {
      setImgIndex(0);
      clearInterval(intervalId);
    };
  }, []);
  const clinicianImagesStack = clinicians.map((clinician, idx) => {
    return (
      <div
        className={getClinicianClassName({
          currentImgID: imgIndex,
          imgID: idx,
        })}
        key={idx}
        style={{ backgroundImage: `url('${clinician.photo}')` }}
      >
        <div className="clinician__image"></div>
        <div className="clinician__detail">
          <div className="clinician__name">{clinician.name}</div>
          <div className="clinician__specialty">
            <span>
              <BsCheckLg />
            </span>
            {clinician.specialty}
          </div>
        </div>
      </div>
    );
  });
  return (
    <Row className="clinicians">
      <Col className="mb-4" md="8">
        <h2 className="clinicians__title">
          Meet our network of licensed and experienced clinicians
        </h2>
        <p className="clinicians__description">
          We cover a wide range of topics and conditions to match you with a
          clinician that understands the issues you care about the most.
        </p>
        <div className="clinicians__specialty">
          <ul role="list" className="specialty-list">
            <li className="specialty-item">
              <span>
                <BsCheckLg />
              </span>
              Depression &amp; Anxiety
            </li>
            <li className="specialty-item">
              <span>
                <BsCheckLg />
              </span>
              PTSD
            </li>
            <li className="specialty-item">
              <span>
                <BsCheckLg />
              </span>
              Bipolar Disorder
            </li>
            <li className="specialty-item">
              <span>
                <BsCheckLg />
              </span>
              Anger Management
            </li>
            <li className="specialty-item">
              <span>
                <BsCheckLg />
              </span>
              Substance Abuse
            </li>
            <li className="specialty-item">
              <span>
                <BsCheckLg />
              </span>
              Stress
            </li>
            <li className="specialty-item">
              <span>
                <BsCheckLg />
              </span>
              Insomnia
            </li>
          </ul>
          <ul role="list" className="specialty-list">
            <li className="specialty-item">
              <span>
                <BsCheckLg />
              </span>
              Autism Spectrum Disorders
            </li>
            <li className="specialty-item">
              <span>
                <BsCheckLg />
              </span>
              Schizophrenia
            </li>
            <li className="specialty-item">
              <span>
                <BsCheckLg />
              </span>
              OCD
            </li>
            <li className="specialty-item">
              <span>
                <BsCheckLg />
              </span>
              ADHD
            </li>
            <li className="specialty-item">
              <span>
                <BsCheckLg />
              </span>
              Relationships
            </li>
            <li className="specialty-item">
              <span>
                <BsCheckLg />
              </span>
              Abuse
            </li>
            <li className="specialty-item">
              <span>
                <BsCheckLg />
              </span>
              Trauma &amp; Grief
            </li>
          </ul>
        </div>
      </Col>
      <Col md="4">
        <div className="clinicians__carousel">{clinicianImagesStack}</div>
      </Col>
    </Row>
  );
};

export default CliniciansSection;
