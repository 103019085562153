import React, { useState } from "react";
import {
  CNavItem,
  CNavbar,
  CContainer,
  CNavbarBrand,
  CNavbarToggler,
  CCollapse,
  CNavbarNav,
  CNavLink,
  CAlert,
  CAlertHeading,
} from "@coreui/react";
import "./header.scss";

const CLIENT_FE_ENDPOINT = `${
  require(`/config/${process.env.NODE_ENV}.json`).CLIENT_FE_ENDPOINT
}`;

const Header = () => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <CAlert
        color="warning"
        style={{ "--cui-alert-border-radius": "0px", textAlign: "center" }}
      >
        <CAlertHeading component="h5">
          Talkdoc is currently not accepting new patients.
        </CAlertHeading>
      </CAlert>
      <CNavbar expand="lg" colorScheme="light" color="string">
        <CContainer fluid>
          <CNavbarBrand href="/">talkdoc</CNavbarBrand>
          <CNavbarToggler onClick={() => setVisible(!visible)} />
          <CCollapse className="navbar-collapse" visible={visible}>
            <CNavbarNav>
              <CNavItem>
                <CNavLink href="/clinicians">For Clinicians</CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink href={`${CLIENT_FE_ENDPOINT}/login`}>
                  Sign in
                </CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink
                  className="nav-link--primary"
                  href={`${CLIENT_FE_ENDPOINT}/signup`}
                >
                  Sign up
                </CNavLink>
              </CNavItem>
            </CNavbarNav>
          </CCollapse>
        </CContainer>
      </CNavbar>
    </>
  );
};

export default Header;
