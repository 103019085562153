import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux'
import rootReducer from '../store/reducers';
// import { Logger } from 'libs/logger';
// import { AnalyticalLogger } from 'libs/analytical';

export const renderPage = (component, customizedState = {}) => {
  // Logger.init();
  // AnalyticalLogger.init();

  const store = createStore(rootReducer, {...window.__PRELOADED_STATE__, ...customizedState});
  delete window.__PRELOADED_STATE__;

  ReactDOM.hydrate(
    <Provider store={store}>
      {component}
    </Provider>,
    document.getElementById('root')
  );
}

export default renderPage;
