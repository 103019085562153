import React from 'react';
import { Row, Col } from 'reactstrap';
import {CCarousel, CCarouselItem} from '@coreui/react';
import {VIEW_MODE, useViewMode} from '../../libs/hooks/useViewMode';
import './testimonials.scss';

const TESTIMONIALS = [
  "The quality of care has been really good, we’re really glad we found our doctor when our local place stopped accepting patients.",
  "I really enjoyed talking to my doctor. I saw her postpartum and I really took the time to think about what she was saying, it was thought-provoking.",
  "I have two young sons and so far the treatment has been great. Our doctor put them on medication, adjusted it when needed and so far the improvement has been very good.  We really like the Talkdoc platform.",
  "She's a great therapist. She's an excellent doctor, she's very professional and experienced. I've known her for many years. There's been a lot of improvements in myself. I’m more active, I'm doing more things around the house.",
  "Definitely positive changes I see for my grandchildren and myself. It helped my grandchildren with ADHD and put anger issues under control. They listen and understand even with children.",
  "Because of her help, I'm able to function as someone neurotypical. She looks at you like a person, instead of a machine or something you have to fix, and listens to what you say, giving you advice and prescriptions based on that.",
  "She listens to our questions and worries, and is very thorough so I have a clear picture of what she's saying. As part of the treatment, she will give suggestions and if I’m not comfortable with it, she is fine with trying new things.",
  "She’s been my doctor for maybe over 1 year. We have really good conversation about getting well, eating well, and my psychiatric needs. She's been really good to me.",
  "I love going there, I haven't had any issues with any of the doctors there. I've done therapy with them and taken medications.",
];

const COLUMN_NUM_WIDE = 3;
const COLUMN_NUM_NARROW = 1;

const Testimonials = ({ testimonials = TESTIMONIALS }) => {
  const columnNumber = useViewMode() === VIEW_MODE.NARROW ? COLUMN_NUM_NARROW : COLUMN_NUM_WIDE;
  let children = [];
  let i = 0;
  while(i < testimonials.length) {
    children.push(
      <CCarouselItem className="testimonials__slider" key={i}>
        <div className="testimonials__block">
        {testimonials.slice(i, i + columnNumber).map((testimonial, index) => (
          <div className="testimonials__item" key={index}>
            {testimonial}
          </div>
        ))}
        </div>
      </CCarouselItem>
    );
    i = i + columnNumber;
  }
  return (
    <Row className="testimonials">
      <Col>
        <h2 className="section__title">What they're saying</h2>
        <CCarousel controls dark>
          {children}
        </CCarousel>
      </Col>
    </Row>
  );
};

export default Testimonials;
