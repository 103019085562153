require('dotenv').config()
import React from 'react';
import { Row, Col } from 'reactstrap';
import { CFooter, CLink } from '@coreui/react';
import './footer.scss';

// const CLIENT_HOST = 'https://dxql6l7391yjm.cloudfront.net/public/img'
let CLIENT_HOST = `${process.env.CLIENT_HOST}/public/img`

if (process.env.NODE_ENV === 'local')
CLIENT_HOST = `assets/img`

const Footer = () => {
  return (
    <CFooter>
      <div className="footer-container">
        <p className="footer-text">The telemedicine services made available through Talkdoc are provided by licensed clinicians practicing within an independently-owned entity known as “Talkdoc Health”. Talkdoc Inc. does not itself provide any mental health or other healthcare provider services.</p>
        <div className="footer-content">
          <div className="footer-item">
            <CLink href="/index.html"><img src={`${CLIENT_HOST}/talkdoc_white_logo.svg`} alt="talkdoc" /></CLink>
          </div>
          <div className="footer-item">
            <h2 className="footer-heading">Company</h2>
            <ul>
              <li><CLink className="footer-link" href="/about">About Us</CLink></li>
              <li>
                <CLink className="footer-link" href="https://www.talkdoc.com/clinicians#job-section">Careers</CLink>
                <CLink className="footer-link footer-link--primary" href="https://angel.co/company/talkdoc/jobs">We&#x27;re Hiring!</CLink>
              </li>
              <li><CLink className="footer-link" href="/termsofservice">Terms of Service</CLink></li>
              <li><CLink className="footer-link" href="/privacy">Privacy Policy</CLink></li>
            </ul>
          </div>
          <div className="footer-item">
            <h2 className="footer-heading">Questions?</h2>
            <ul>
              <li>Call us Monday - Friday 8am - 5pm PT</li>
              <li><CLink className="footer-link" href="tel:+18442222205">1-844-222-2205</CLink></li>
            </ul>
            <div className="footer-text emergency-footer">If you or someone else are in a life-threatening situation, don&#x27;t use this site. Call <a href="tel:1-800-273-8255" className="footer-emergency-link">1-800-273-8255</a> or use these <a href="/resources" className="footer-emergency-link">resources</a> to get immediate help. <strong>If this is an emergency, call 911.</strong></div>
          </div>
        </div>
        <p className="footer-text">&copy; 2023 Talkdoc, Inc.</p>
      </div>
    </CFooter>
  );
};

export default Footer;