import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "reactstrap";
import classNames from "classnames";
import "./howItWorksSection.scss";

const SIGNUP_ENDPOINT = `${
  require(`/config/${process.env.NODE_ENV}.json`).CLIENT_FE_ENDPOINT
}/signup`;

const HowItWorksSection = ({
  className,
  heading = "How it works",
  imageUrl,
  contentList,
}) => {
  const [pathName, setPathName] = useState("");

  useEffect(() => {
    if (window) {
      setPathName(window.location.pathname);
    }
  }, []);

  return (
    <Row className={classNames("how-it-works", { [className]: className })}>
      <Col className="how-it-works__content">
        <h2 className="section__title">{heading}</h2>
        <Row>
          <Col className="how-it-works__image" md="6">
            <img src={imageUrl} alt="how-it-works" />
          </Col>
          <Col className="how-it-works__items" md="6">
            {contentList.map((item) => (
              <div className="how-it-works__item" key={item.title}>
                <h3 className="how-it-works__item__heading">{item.title}</h3>
                <p className="how-it-works__item__description">
                  {item.description}
                </p>
              </div>
            ))}
            {pathName !== "/newyork" ? (
              <Button className="button--primary" href={SIGNUP_ENDPOINT}>
                Get started
              </Button>
            ) : (
              <Button className="button--primary" href="/waitlist">
                Join our waitlist today
              </Button>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default HowItWorksSection;
