import React from 'react';
import { Row, Col } from 'reactstrap';
import classNames from 'classnames';
import './heroSection.scss';

const HeroSection = ({ className, id, heading, description, ctaContent, imageUrl, layout = {} }) => {
  const { mdLeft = "7", mdRight = "5", lgLeft = "6", lgRight = "5", reverse = false } = layout;
  return (
    <Row id={id} className={classNames('hero', {[className]: className, 'hero-reverse': reverse})}>
      <Col className="hero__content" md={mdLeft} lg={lgLeft}>
        <h1 className="hero__title">{heading}</h1>
        <h2 className="hero__description">{description}</h2>
        {ctaContent}
      </Col>
      <Col className="hero__image" md={mdRight} lg={lgRight}>
        <img src={imageUrl} alt="talkdoc-session" />
      </Col>
    </Row>
  )
};

export default HeroSection;
