require("dotenv").config();

import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import HeroSection from "../../components/hero/HeroSection";
import FeaturesSection from "../../components/features/FeaturesSection";
import HowItWorksSection from "../../components/howItWorks/HowItWorksSection";
import Testimonials from "../../components/testimonials/Testimonials";
import CliniciansSection from "../../components/clinicians/CliniciansSection";
import "./homeContent.scss";

let CLIENT_HOST = `${process.env.CLIENT_HOST}/public/img`;
const SIGNUP_ENDPOINT = `${
  require(`/config/${process.env.NODE_ENV}.json`).CLIENT_FE_ENDPOINT
}/signup`;

if (process.env.NODE_ENV === "local") CLIENT_HOST = `assets/img`;

const features = [
  {
    icon: `${CLIENT_HOST}/vpheart.svg`,
    title: "No copays, no extra fees",
    description:
      "If you’re on one of our supported Medicaid plans, you qualify for free therapy.",
  },
  {
    icon: `${CLIENT_HOST}/vpmedkit.svg`,
    title: "Qualified providers",
    description: "All our clinicians are board certified or board eligible",
  },
  {
    icon: `${CLIENT_HOST}/vpphone.svg`,
    title: "Off-hour appointments",
    description:
      "Flexible scheduling for getting support whenever you need it.",
  },
];

const howItWorks = [
  {
    title: "1. Check your eligibility",
    description:
      "We'll use your Medicaid number and date of birth to check your eligibility for free therapy and psychiatry.",
  },
  {
    title: "2. Tell us about yourself",
    description:
      "Match with our clinicians based on your treatment needs, language, and gender preferences.",
  },
  {
    title: "3. Schedule an appointment",
    description:
      "Simply select a time that works for you to setup an introductory call with your new clinician. You're all set!",
  },
];

const HomeContent = () => {
  return (
    <Container className="visitor-home">
      <HeroSection
        id="Home-Hero"
        heading={"Get online therapy"}
        description={
          "Match with experienced psychiatrists and therapists, 100% free of charge for eligible Medicaid health plans, and affordable without insurance."
        }
        imageUrl={`${CLIENT_HOST}/heroImage.gif`}
        ctaContent={
          <Button className="button--primary" href={SIGNUP_ENDPOINT}>
            Start today
          </Button>
        }
      />
      <FeaturesSection features={features} />
      <HowItWorksSection
        imageUrl={`${CLIENT_HOST}/howitworks.svg`}
        contentList={howItWorks}
      />
      <Testimonials />
      <Row className="clinicians__wrapper">
        <CliniciansSection />
      </Row>
      <HeroSection
        className={"about-us"}
        heading={"We've helped thousands of people lead healthier lives"}
        description={
          "Your mental health matters — if you're on Medicaid, sign up today to see if you qualify for free therapy and psychiatry."
        }
        imageUrl={`${CLIENT_HOST}/aboutUs.gif`}
        layout={{ reverse: true }}
        ctaContent={
          <Button className="button--primary" href={SIGNUP_ENDPOINT}>
            Get started
          </Button>
        }
      />
    </Container>
  );
};

export default HomeContent;
