import React, { useEffect, useState } from 'react';
import _throttle from 'lodash/throttle';

export const SCREEN_WIDTH_TABLET = 768;

export const VIEW_MODE = {
    UNKNOWN: 0,
    WIDE: 1,
    NARROW: 2,
};

const getWindowWidth = () => {
    const width = window && window.innerWidth;
    return width ? width : 0;
};

export function useViewMode() {
    const [viewMode, setViewMode] = useState(VIEW_MODE.UNKNOWN);

    const resizeHandler = () => {
        const windowWidth = getWindowWidth();
        const newViewMode = windowWidth > SCREEN_WIDTH_TABLET ? VIEW_MODE.WIDE : VIEW_MODE.NARROW;
        if (newViewMode !== viewMode) {
            setViewMode(newViewMode);
        }
    };

    useEffect(() => {
        resizeHandler();
        const _resizeListener = _throttle(resizeHandler, 100);
        window.addEventListener('resize', _resizeListener);
        return () => {
            window.removeEventListener('resize', _resizeListener);
        }
    }, []);

    return viewMode;
};

export default useViewMode;
