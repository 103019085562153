import React, { useState, useEffect } from 'react';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import './marketingPage.scss';
import NewLaunchHeader from '../newLaunchHeader/newLaunchHeader';

const MarketingPage = ({className, children}) => {
  const NEWLAUNCHPATHNAMES = ['/newyork', '/waitlist'];

  const [useNewLaunchHeader, setUseNewLaunchHeader] = useState(false);

  useEffect(() => {
    if(window) {
      const pathName = window.location.pathname;
      setUseNewLaunchHeader(NEWLAUNCHPATHNAMES.includes(pathName));
    }
  }, []);

  return (
    <div className={`marketing-page`}>
      { useNewLaunchHeader 
        ? <NewLaunchHeader />
        : <Header />
      }
      {children}
      <Footer />
    </div>
  );
};

export default MarketingPage;
