import React from 'react';
import { Row, Col } from 'reactstrap';
import './featuresSection.scss';

const FeaturesSection = ({ features = [] }) => {
  return (
    <Row className="feature">
      {features.map(feature => (
        <Col className="feature__item" md="4" key={feature.title}>
          <img src={feature.icon} alt="talkdoc-session" />
          <h3 className="feature__title">{feature.title}</h3>
          <p className="feature__description">{feature.description}</p>
        </Col>
      ))}
    </Row>
  );
}

export default FeaturesSection;
